<template>
  <div class="flex min-h-screen">
    <BusinessInfoSideBar />
    <LocatiomUserUpdate />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import BusinessInfoSideBar from '@/components/BusinessInfoSideBar.vue'
import LocatiomUserUpdate from '@/components/LocationUserUpdate.vue'
export default defineComponent({
  name: 'LocationUserUpdate',
  components: {
    BusinessInfoSideBar,
    LocatiomUserUpdate
  }
})
</script>

<style scoped></style>