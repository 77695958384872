
import { defineComponent } from 'vue'
import BusinessInfoSideBar from '@/components/BusinessInfoSideBar.vue'
import LocatiomUserUpdate from '@/components/LocationUserUpdate.vue'
export default defineComponent({
  name: 'LocationUserUpdate',
  components: {
    BusinessInfoSideBar,
    LocatiomUserUpdate
  }
})
