
import { defineComponent, ref, watch } from 'vue'
import ErrorMessage from '@/components/UI/ErrorMessage.vue'
import { useStore } from 'vuex'
import libphonenumber from 'google-libphonenumber'
import PhoneNumber from '@/components/UI/PhoneNumber.vue'
import Spinner from '@/components/UI/Spinner.vue'

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance()

export default defineComponent({
  components: {
    ErrorMessage,
    PhoneNumber,
    Spinner
  },
  setup() {
    const store = useStore()

    const showLoader = ref(false)
    const firstName = ref(store.state.user.firstName)
    const lastName = ref(store.state.user.lastName)
    const phone = ref(store.state.user.phone)
    const password = ref(store.state.user.password)
    const passwordType = ref('password')
    const passwordError = ref('')
    const phoneError = ref('')
    const passwordErrors = ref({
      '8 characters long': false,
      '1 lowercase': false,
      '1 uppercase': false,
      '1 number and 1 special character': false
    })

    const formErrors = ref({
      firstNameError: false,
      lastNameError: false,
      phoneError: false,
      passwordError: false
    })

    watch(password, newPassword => {
      if (newPassword.length >= 8)
        passwordErrors.value['8 characters long'] = true
      else passwordErrors.value['8 characters long'] = false
      if (newPassword.match(/^(?=.*[A-Z])/))
        passwordErrors.value['1 uppercase'] = true
      else passwordErrors.value['1 uppercase'] = false
      if (newPassword.match(/^(?=.*[a-z])/))
        passwordErrors.value['1 lowercase'] = true
      else passwordErrors.value['1 lowercase'] = false
      if (newPassword.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])/))
        passwordErrors.value['1 number and 1 special character'] = true
      else passwordErrors.value['1 number and 1 special character'] = false
      passwordError.value = ''
      formErrors.value['passwordError'] = false
      for (const error of Object.keys(passwordErrors.value)) {
        if (!passwordErrors.value[error]) {
          formErrors.value['passwordError'] = true
          if (!passwordError.value) {
            passwordError.value = error
          } else {
            passwordError.value = `${passwordError.value}, ${error}`
          }
        }
      }
    })

    watch(phone, newPhone => {
      phoneError.value = ''
      formErrors.value['phoneError'] = false
      if (!newPhone) {
        formErrors.value['phoneError'] = true
        phoneError.value = 'Phone number is required'
      } else {
        try {
          const phoneNumber = phoneUtil.parse(
            newPhone,
            store.state.user.countryCode
          )
          if (!phoneUtil.isValidNumber(phoneNumber)) {
            formErrors.value['phoneError'] = true
            phoneError.value = 'Invalid phone number'
          }
        } catch (err) {
          formErrors.value['phoneError'] = true
          phoneError.value = 'Invalid phone number'
        }
      }
    })

    const verifyErrors = () => {
      if (!firstName.value || !firstName.value.trim())
        formErrors.value.firstNameError = true
      if (!lastName.value || !lastName.value.trim())
        formErrors.value.lastNameError = true
      if (!password.value || !password.value.trim()) {
        formErrors.value.passwordError = true
        passwordError.value = 'Password is required'
      }
      if (!phone.value || !phone.value.trim()) {
        formErrors.value.phoneError = true
        phoneError.value = 'Phone number is required'
      }
    }

    const save = () => {
      showLoader.value = true
      verifyErrors()
      for (const error in formErrors.value) {
        if (formErrors.value[error]) {
          showLoader.value = false
          return
        }
      }
      store.commit('user/set', {
        firstName: firstName.value.trim(),
        lastName: lastName.value.trim(),
        phone: phone.value,
        password: password.value.trim()
      })

      // @ts-ignore
      store?.state?.iframe?.handshake?.then(parent => {
        parent.emit('user-data', JSON.stringify(store.state.user))
        store.commit('user/set', {
          firstName: '',
          lastName: '',
          phone: '',
          password: ''
        })
      })
    }

    const updatePasswordType = () => {
      if (passwordType.value === 'password') passwordType.value = 'text'
      else passwordType.value = 'password'
    }

    return {
      firstName,
      lastName,
      phone,
      password,
      save,
      formErrors,
      passwordType,
      updatePasswordType,
      passwordError,
      showLoader,
      phoneError
    }
  }
})
