
import { defineComponent, computed, WritableComputedRef, ref, watch } from 'vue'
import { useStore } from 'vuex'
import libphonenumber from 'google-libphonenumber'

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance()

export default defineComponent({
  props: ['placeholder', 'customClass', 'value'],
  setup(props, { emit }) {
    console.log(props)
    const store = useStore()
    const phone = ref(props.value)
    const formattedPhone: WritableComputedRef<string> = computed({
      get(): string {
        if (!phone.value) return ''
        try {
          const parsedNumber = phoneUtil.parse(phone.value)
          if (
            phoneUtil.getRegionCodeForNumber(parsedNumber) &&
            phoneUtil.getRegionCodeForNumber(parsedNumber) !==
              store.state.user.countryCode
          ) {
            return phoneUtil.format(
              parsedNumber,
              libphonenumber.PhoneNumberFormat.INTERNATIONAL
            )
          } else if (phoneUtil.getRegionCodeForNumber(parsedNumber)) {
            return phoneUtil.format(
              parsedNumber,
              libphonenumber.PhoneNumberFormat.NATIONAL
            )
          }
          return phone.value
        } catch (e) {
          return phone.value
        }
      },
      set(newPhone: string): void {
        try {
          const e164 = phoneUtil.format(
            phoneUtil.parse(newPhone, store.state.user.countryCode),
            libphonenumber.PhoneNumberFormat.E164
          )
          phone.value = e164
          emit('input', e164)
        } catch (err) {
          phone.value = newPhone
          emit('input', newPhone)
        }
      }
    })

    watch(
      () => props.value,
      newValue => {
        phone.value = newValue
      }
    )

    return {
      formattedPhone
    }
  }
})
