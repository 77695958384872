<template>
  <div class="form-parent-container">
    <div class="form-child-container">
      <div class="md:text-lg lg:text-xl xl:text-2xl font-medium text-center">
        Enter your personal details below:
      </div>
      <div class="md:pt-3 lg:pt-6 xl:pt-6 flex flex-row">
        <div class="flex w-1/2 flex flex-row justify-start item-start">
          <div class="w-11/12 flex flex-col">
            <label id="listbox-label" class="label">
              First Name <span style="color:red;">*</span>
            </label>
            <input
              v-model="firstName"
              type="text"
              name="firstName"
              class="input-text"
              placeholder="First Name"
              @input="formErrors.firstNameError = false"
            />
            <error-message
              :isError="formErrors.firstNameError"
              message="First name is required."
            />
          </div>
        </div>
        <div class="flex w-1/2 flex flex-row justify-end item-end">
          <div class="w-11/12 flex flex-col">
            <label id="listbox-label" class="label">
              Last Name <span style="color:red;">*</span>
            </label>
            <input
              v-model="lastName"
              type="text"
              name="lastName"
              class="input-text"
              placeholder="Last Name"
              @input="formErrors.lastNameError = false"
            />
            <error-message
              :isError="formErrors.lastNameError"
              message="Last name is required."
            />
          </div>
        </div>
      </div>
      <div class="flex flex-col md:pt-5 lg:pt-10 xl:pt-10">
        <label id="listbox-label" class="label">
          Phone <span style="color:red;">*</span>
        </label>
        <PhoneNumber
          placeholder="Phone Number"
          v-model="phone"
          name="phone"
          autocomplete="phone"
          class="input-text"
        />
        <error-message :isError="formErrors.phoneError" :message="phoneError" />
      </div>
      <div class="flex flex-col md:pt-5 lg:pt-10 xl:pt-10">
        <label id="listbox-label" class="label">
          Password <span style="color:red;">*</span>
        </label>
        <div class="relative w-full text-gray-400 focus-within:text-gray-600">
          <div
            class="absolute inset-y-0 right-0 flex items-center cursor-pointer mx-2"
            @click="updatePasswordType"
            :class="passwordType === 'text' ? ['opacity-30'] : ''"
          >
            <!-- Heroicon name: search -->
            <img src="@/assets/images/eye.svg" alt="search-logo" />
          </div>
          <input
            class="block w-full h-full pr-9 py-3 border-transparent text-gray-900 placeholder-gray-400 hover:outline-none hover:ring-indigo-500 focus:border-indigo-500 focus:border-transparent sm:text-sm border-gray-300"
            autocomplete="off"
            :type="passwordType"
            name="search"
            v-model="password"
          />
        </div>
        <error-message
          :isError="formErrors.passwordError"
          :message="passwordError"
        />
      </div>
      <div class="md:pt-14 lg:pt-20 xl:pt-20 flex justify-end">
        <button
          class="base-filled-button indigo-btn flex justify-center items-center"
          :disabled="Object.values(formErrors)?.includes(true) || showLoader"
          @click="save"
        >
          <spinner v-if="showLoader" />
          <span v-else class="mr-1">Save</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import ErrorMessage from '@/components/UI/ErrorMessage.vue'
import { useStore } from 'vuex'
import libphonenumber from 'google-libphonenumber'
import PhoneNumber from '@/components/UI/PhoneNumber.vue'
import Spinner from '@/components/UI/Spinner.vue'

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance()

export default defineComponent({
  components: {
    ErrorMessage,
    PhoneNumber,
    Spinner
  },
  setup() {
    const store = useStore()

    const showLoader = ref(false)
    const firstName = ref(store.state.user.firstName)
    const lastName = ref(store.state.user.lastName)
    const phone = ref(store.state.user.phone)
    const password = ref(store.state.user.password)
    const passwordType = ref('password')
    const passwordError = ref('')
    const phoneError = ref('')
    const passwordErrors = ref({
      '8 characters long': false,
      '1 lowercase': false,
      '1 uppercase': false,
      '1 number and 1 special character': false
    })

    const formErrors = ref({
      firstNameError: false,
      lastNameError: false,
      phoneError: false,
      passwordError: false
    })

    watch(password, newPassword => {
      if (newPassword.length >= 8)
        passwordErrors.value['8 characters long'] = true
      else passwordErrors.value['8 characters long'] = false
      if (newPassword.match(/^(?=.*[A-Z])/))
        passwordErrors.value['1 uppercase'] = true
      else passwordErrors.value['1 uppercase'] = false
      if (newPassword.match(/^(?=.*[a-z])/))
        passwordErrors.value['1 lowercase'] = true
      else passwordErrors.value['1 lowercase'] = false
      if (newPassword.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])/))
        passwordErrors.value['1 number and 1 special character'] = true
      else passwordErrors.value['1 number and 1 special character'] = false
      passwordError.value = ''
      formErrors.value['passwordError'] = false
      for (const error of Object.keys(passwordErrors.value)) {
        if (!passwordErrors.value[error]) {
          formErrors.value['passwordError'] = true
          if (!passwordError.value) {
            passwordError.value = error
          } else {
            passwordError.value = `${passwordError.value}, ${error}`
          }
        }
      }
    })

    watch(phone, newPhone => {
      phoneError.value = ''
      formErrors.value['phoneError'] = false
      if (!newPhone) {
        formErrors.value['phoneError'] = true
        phoneError.value = 'Phone number is required'
      } else {
        try {
          const phoneNumber = phoneUtil.parse(
            newPhone,
            store.state.user.countryCode
          )
          if (!phoneUtil.isValidNumber(phoneNumber)) {
            formErrors.value['phoneError'] = true
            phoneError.value = 'Invalid phone number'
          }
        } catch (err) {
          formErrors.value['phoneError'] = true
          phoneError.value = 'Invalid phone number'
        }
      }
    })

    const verifyErrors = () => {
      if (!firstName.value || !firstName.value.trim())
        formErrors.value.firstNameError = true
      if (!lastName.value || !lastName.value.trim())
        formErrors.value.lastNameError = true
      if (!password.value || !password.value.trim()) {
        formErrors.value.passwordError = true
        passwordError.value = 'Password is required'
      }
      if (!phone.value || !phone.value.trim()) {
        formErrors.value.phoneError = true
        phoneError.value = 'Phone number is required'
      }
    }

    const save = () => {
      showLoader.value = true
      verifyErrors()
      for (const error in formErrors.value) {
        if (formErrors.value[error]) {
          showLoader.value = false
          return
        }
      }
      store.commit('user/set', {
        firstName: firstName.value.trim(),
        lastName: lastName.value.trim(),
        phone: phone.value,
        password: password.value.trim()
      })

      // @ts-ignore
      store?.state?.iframe?.handshake?.then(parent => {
        parent.emit('user-data', JSON.stringify(store.state.user))
        store.commit('user/set', {
          firstName: '',
          lastName: '',
          phone: '',
          password: ''
        })
      })
    }

    const updatePasswordType = () => {
      if (passwordType.value === 'password') passwordType.value = 'text'
      else passwordType.value = 'password'
    }

    return {
      firstName,
      lastName,
      phone,
      password,
      save,
      formErrors,
      passwordType,
      updatePasswordType,
      passwordError,
      showLoader,
      phoneError
    }
  }
})
</script>

<style scoped>
.input-text {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
</style>
